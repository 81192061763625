import React from "react";
import BlockContent from "../components/block-content";

function Services(props) {

    return (

        <div className="items-center">
            {props
                .nodes[0]
                .services
                .map((services, i) => (
                    <div className="relative" key={i}>
                        {/* <div className="absolute flex ml-5 mt-2 items-center justify-center h-2 w-2">
                            <span
                                className="animate-pulse relative inline-flex rounded-full h-full w-full bg-black"></span>
                        </div> */}
                        <p className="!mb-0 text-lg leading-6 font-bold text-gray-900">{services.serviceTitle}</p>
                        <div className="text-base text-gray-500"><BlockContent blocks={services._rawServiceDescription || []}/></div>
                    </div>
                ))}

        </div>
    )
}

export default Services;