import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

const Clients = (props) => {

    const data = useStaticQuery(graphql `
    query clientLogos {
        allSanityClients(sort: {fields: orderRank}) {
          edges {
            node {
              logo {
                asset {
                  gatsbyImageData (placeholder: NONE)
                }
                alt
              }
              visible
              website
            }
          }
        }
      }         
  `);

    const logoData = data.allSanityClients.edges;

    return (
        <section id="clients" className="p-5 mt-4">
            <div className="container mx-auto">
                <div className="flex flex-wrap justify-center align-center">
                    {logoData.map((image, i) => (
                        <React.Fragment key={i}>
                            {image.node.visible && image.node.logo && <a
                                href={image.node.website}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="mx-5 my-5 flex justify-center h-16 w-16 md:h-28 md:w-28 lg:h-36 lg:w-36">
                                <GatsbyImage
                                    image={image.node.logo.asset.gatsbyImageData}
                                    objectFit="contain"
                                    alt={image.node.logo.alt}/></a>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Clients;
