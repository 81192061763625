import React from 'react';
import {useStaticQuery, graphql} from "gatsby";
import {motion} from "framer-motion";
import {buildImageObj} from "../lib/helpers";
import {imageUrlFor} from "../lib/image-url";
// import ModalPeople from './modal-people';

const People = () => {

    const data = useStaticQuery(graphql `
    query PeopleQuery {
      allSanityPeople(sort: {fields: orderRank}) {
        edges {
          node {
            name
            position
            image {
             ...Image
            }
          }
        }
      }
    }               
  `);

    const peopleData = data.allSanityPeople.edges;

    const transition = {
        duration: 0.6,
        ease: [0.43, 0.13, 0.23, 0.96]
    };

    return (
        <section id="people" className="p-5">
            <div className="container mx-auto">
                <div
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {peopleData.map((people, i) => (
                        <React.Fragment key={i}>
                            <div className="preview py-4 md:px-4">
                                <div className="preview-img">
                                    <div className="preview-txt">
                                        <h3 className="preview-title text-2xl mt-3">{people.node.name}</h3>
                                        <h4 className="preview-title text-sm mb-3">{people.node.position}</h4>
                                    </div>
                                    <motion.div
                                        className="people-img zoom"
                                        style={{
                                        backgroundImage: `url(${imageUrlFor(buildImageObj(people.node.image))
                                            .auto('format')
                                            .width(750)
                                            .height(750)
                                            .url()})`
                                    }}
                                        transition={transition}/>
                                </div>
                                {/* <ModalPeople people={people}/> */}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default People;
