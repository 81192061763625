import React from "react";
import {graphql} from "gatsby";
import {mapEdgesToNodes} from "../lib/helpers";
import {buildImageObj} from "../lib/helpers";
import {imageUrlFor} from "../lib/image-url";
import ResponsiveEmbed from "react-responsive-embed";
import ScrollAnimation from "react-animate-on-scroll";
import BlockContent from "../components/block-content";
import PageParallax from "../components/page-parallax";
import ContentParallax from "../components/content-parallax";
import Clients from "../components/clients";
import AboutBlocks from "../components/about-blocks";
import Services from "../components/services";
import People from "../components/people";

import Layout from "../components/layout";
import SEO from "../components/seo";

export const query = graphql `
query AboutPageQuery {
  sanityAboutPage {
    aboutHero {
      ...Image
    }
    introImage {
      ...Image
    }
    _rawIntroText
    showreel {
      url
    }
    aboutImage {
      ...Image
    }
    _rawAboutText
    servicesImage {
      ...Image
    }
    _rawInfoText
    mapAnimation {
      ...Image
    }
    mapSubtitles
    _rawFirstTestimonialText
    firstTestimonialImage {
      ...Image
    }
    _rawSecondTestimonialText
    secondTestimonialImage {
      ...Image
    }
  }
  allSanityAboutPage {
    edges {
      node {
        services {
          serviceTitle
          _rawServiceDescription
        }
        aboutBlocks {
          blockImage {
            ...Image
          }
          blockText
        }
      }
    }
  }
}
`;

const AboutPage = props => {

    const {data} = props;
    const about = data.sanityAboutPage;
    const aboutNodes = data && data.allSanityAboutPage && mapEdgesToNodes(data.allSanityAboutPage);

    return (
        <Layout>
            <SEO title="About Us" image={about.aboutHero}/>
            <PageParallax image={about.aboutHero} title="About Us"/>
            <section id="intro" className="bg-white">
                <div className="2xl:container 2xl:mx-auto 2xl:p-10">
                    <div
                        className="md:h-full grid grid-rows-2 md:grid-rows-1 grid-cols-1 md:grid-cols-2 about-content">
                        <ContentParallax image={about.introImage}/>
                        <div className="m-auto p-10 md:px-20 md:py-40"><BlockContent blocks={about._rawIntroText || []}/></div>
                    </div>
                </div>
            </section>
            <section id="showreel">
                <div className="2xl:container 2xl:mx-auto 2xl:p-10">
                    <ResponsiveEmbed allowFullScreen src={about.showreel.url}/>
                </div>
            </section>
            <Clients {...about}/>
            <AboutBlocks nodes={aboutNodes}/>
            <section id="services" className="bg-white">
                <div className="2xl:container 2xl:mx-auto 2xl:p-10">
                    <div
                        className="h-full grid grid-rows-4 grid-cols-1 md:grid-rows-2 md:grid-cols-2 2xl:space-y-10 about-content">
                        <ContentParallax image={about.aboutImage} order="order-2" />
                        <div className="flex flex-col justify-center p-5 md:px-10 md:py-20 order-1">
                            <BlockContent blocks={about._rawAboutText || []}/>
                        </div>
                        <div
                            className="flex flex-col justify-center p-5 md:px-10 md:py-20 order-3 md:order-4">
                            <Services nodes={aboutNodes}/>
                        </div>
                        <ContentParallax image={about.servicesImage} order="order-4 md:order-3"/>
                    </div>
                </div>
            </section>
            <People/>
            <section id="about-us" className="bg-white">
                <div className="container mx-auto px-5 py-12">
                    <div className="about-content text-center">
                        <BlockContent blocks={about._rawInfoText || []}/></div>
                    <ScrollAnimation delay={500} animateOnce={true} animateIn="fadeIn">
                        <img
                            className="mx-auto"
                            src={`${imageUrlFor(buildImageObj(about.mapAnimation)).url()}`}
                            alt="World Map"/>
                    </ScrollAnimation>
                    <div className="mt-4 text-center">
                        <p className="text-xs">{about.mapSubtitles}</p>
                    </div>
                </div>
            </section>
            <section id="testimonials" className="bg-white">
                <div className="2xl:container 2xl:mx-auto 2xl:pb-10">
                    <div
                        className="h-full grid grid-rows-4 grid-cols-1 md:grid-rows-2 md:grid-cols-2 2xl:space-y-10 about-content">
                        <div
                            className="flex flex-col justify-center p-5 md:p-10 lg:px-10 lg:py-40 order-2 md:order-1">
                            <BlockContent blocks={about._rawFirstTestimonialText || []}/>
                        </div>
                        <ContentParallax
                            order="order-1 md:order-2"
                            image={about.firstTestimonialImage}/>
                        <ContentParallax order="order-3" image={about.secondTestimonialImage}/>
                        <div
                            className="flex flex-col justify-center p-5 md:p-10 lg:px-10 lg:py-40 order-4">
                            <BlockContent blocks={about._rawSecondTestimonialText || []}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default AboutPage;