import React from "react";
import {buildImageObj} from "../lib/helpers";
import {imageUrlFor} from "../lib/image-url";

function AboutBlocks(props) {

    return (
        <section id="about-blocks">
                <div className="grid grid-cols-1 md:grid-cols-3">
                    {props
                        .nodes[0]
                        .aboutBlocks
                        .map((blocks, i) => (
                            <React.Fragment key={i}>
                            <div className="preview">
                                <div className="preview-img">
                                    <div className="preview-txt">
                                        <h3 className="text-2xl mb-3">{blocks.blockText}</h3>
                                    </div>
                                    <div
                                        className="project-img brightness-75"
                                        style={{
                                        backgroundImage: `url(${imageUrlFor(buildImageObj(blocks.blockImage))
                                            .auto('format')
                                            .width(1080)
                                            .height(1080)
                                            .url()})`
                                    }}/>
                                </div>
                            </div>
                        </React.Fragment>
                        ))}
            </div>
        </section>
    )
}

export default AboutBlocks;