import React from "react";
import {Parallax} from "react-parallax";
import {buildImageObj} from "../lib/helpers";
import {imageUrlFor} from "../lib/image-url";

function ContentParallax(props) {
    return (
            <Parallax
                bgImage={imageUrlFor(buildImageObj(props.image))
                .auto('format')
                .width(1920)
                .url()}
                strength={200}
                bgImageAlt="Project Hero Image" 
                contentClassName="h-full"
                className={`${props.order}`}>
            </Parallax>
    );
}

export default ContentParallax;